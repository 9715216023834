import moment from 'moment';
import { isAndroid } from 'react-device-detect';

import _, { isFinite, orderBy } from 'lodash';
import { validate as isValidUUID } from 'uuid';
import ct from 'countries-and-timezones';
import {
    DropdownOptionItem,
    enumItemsType,
} from 'uikit/dropdown/CustomDropdown/CustomOption/CustomOption';
import localStorageKeys from 'constants/localStorageKeys';
import { ExistingColumn } from 'redux/dataImport/dataImport';
import { convertToTimeZone } from 'uikit/datePicker/DatePicker';
import routesPaths from 'constants/routesPaths';
import { ReactComponent as LocationIcon } from 'images/icon_location.svg';
import { ReactComponent as PersonIcon } from 'images/icon_user.svg';
import { ReactComponent as SessionIcon } from 'images/icon_pc.svg';
import constants, {
    API_URL,
    DATE_AND_TIME,
    ENVIRONMENT,
    PIN_TYPES,
    USER_ROLE,
    admin,
    eventUrl,
    hostname,
    isAdminUser,
    mobileToken as mToken,
    mobileType,
} from './constants';
import { downloadFileFromUrl } from './downloadFile';

const apiKey = process.env.REACT_APP_MAP_API_KEY;

export const getWidth = (_width: number, isTablet: boolean): number => {
    if (!isTablet) {
        return _width;
    }
    return _width * 0.75;
};

export const getFormatedDate = (
    date: string | Date,
    dateFormat?: string,
    isUtc = false
): string => {
    if (date) {
        if (dateFormat) {
            const isServerRunningLocally = API_URL.includes('localhost');
            // when date need to send to backend will convert local date into utc format
            if (isUtc && !isServerRunningLocally)
                return moment(date).utc().format(dateFormat);
            return moment(date).format(dateFormat);
        }
        return moment(date).format('YYYY-MM-DD');
    }
    return '';
};

export const getDateInUTC = (date: string | Date): string =>
    moment(date).utc().format();

export const getTimeInFormat = (date: Date, hour12 = true): string =>
    date?.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12,
    });

export const getDateForPreview = (
    date: string | Date,
    format?: string,
    dateValueFormat?: string
): string =>
    date
        ? getFormatedDate(
              new Date(
                  !dateValueFormat
                      ? moment(date).format('MM/DD/YYYY HH:mm:ss')
                      : moment(date, dateValueFormat).format(
                            'MM/DD/YYYY HH:mm:ss'
                        )
              ),
              format || constants.GLOBAL.MMDDYYYY_DATE_FORMATE
          )
        : '';

export const getDateForPreviewInLocalTimezone = (
    date: string | Date,
    format?: string
): string =>
    getDateForPreview(
        convertToTimeZone(date),
        format || constants.GLOBAL.MMDDYYYY_DATE_FORMATE
    );

export const getDateMiliseconds = (date?: string) => {
    if (date) {
        return new Date(getFormatedDate(date, 'MM/DD/YYYY HH:mm:ss')).getTime();
    }
    return new Date(
        getFormatedDate(new Date(), 'MM/DD/YYYY HH:mm:ss')
    ).getTime();
};

export const convertStringToValideCamelCaseString = (str: string): string =>
    str
        .replace(/\s(.)/g, ($1) => $1.toUpperCase())
        .replace(/\s/g, '')
        .replace(/^(.)/, ($1) => $1.toLowerCase());

// function which will convert string into lower case and trim its value and return final result
export const getStringForComparission = (str: string): string => {
    if (str) return str.toLocaleLowerCase().trim();
    return '';
};

export const convertCamelCaseToSentanseCase = (
    _text: string | undefined
): string => {
    if (!_text) return '';
    return _.startCase(_text);
};

// getStringCommaSeparated: this function is used to show comman separated string from array items
export const getStringCommaSeparated = (
    _items: string[],
    _message: string,
    _singularMessage?: string,
    _symbole = ''
): string => {
    let fileTypesString = '';
    _items.map((item, index) => {
        if (_items.length === 1) {
            fileTypesString += `${_symbole}${item} ${
                _singularMessage || _message
            }`;
        } else if (_items.length === index + 1) {
            fileTypesString += `and ${_symbole}${item} ${_message}`;
        } else {
            fileTypesString += `${_symbole}${item}${
                _items.length - 2 !== index ? ', ' : ' '
            }`;
        }
        return true;
    });
    return fileTypesString;
};

export const validateEmail = (email: string): boolean => {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};

export const validatePassword = (password: string): boolean => {
    const re =
        /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>]).{8,}$/;

    return re.test(password);
};

export const validateId = (id: string): boolean => isValidUUID(id);

export const validatePhoneNumber = (phone: string): boolean => {
    const re = /^[0-9]+$/;
    if (phone.length !== 10) return false;
    return re.test(String(phone).toLowerCase());
};

export const validateIsNumeric = (value: any): boolean =>
    /^-?\d+$/.test(value) && value >= 0;

export const isValidDateTime = (value: string, format: string): boolean =>
    moment(value, format, true).isValid();

type emptyPropType = string | number | [] | {} | undefined | null;

export const isEmpty = (value: emptyPropType): boolean => {
    const emptyAry = [
        '',
        undefined,
        'undefined',
        null,
        'null',
        [],
        '[]',
        {},
        '{}',
    ];
    if (emptyAry.includes(value)) {
        return true;
    }
    return false;
};

export const getFileName = (filePath: string): string =>
    filePath.replace(/\.[^/.]+$/, '');

export const validateLink = (link: string): boolean => {
    // Make the subdomain part optional by adding (?:www\.)?
    const regexForLink =
        /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|(?:www\.)?[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|(?:www\.)?[a-zA-Z0-9]+\.[^\s]{2,})/gi;

    // if link contains any space then return invalid
    const regexForSpace = /\s/;
    if (regexForSpace.test(link)) return false;

    // if not http or https then return invalid
    if (!/(http(s?)):\/\//i.test(link)) return false;

    return regexForLink.test(link);
};

export const isStringContains = (string: string, subString: string): boolean =>
    string.includes(subString);

export const validateLatitude = (num: number) =>
    isFinite(+num) && Math.abs(+num) <= 90;

export const validateLongitude = (num: number) =>
    isFinite(+num) && Math.abs(+num) <= 180;

/**
 * NOTE: if we add any new type here then we will also need to support that in reg form condition component
 * so do update fields array in constants file OPERATORS: [] as per type
 */
const getLabel = (value: string) => {
    if (value === 'STRING') {
        return 'Free Text';
    }
    if (value === 'BOOLEAN') {
        return 'Y/N';
    }
    if (value === 'DATE') {
        return 'Date';
    }
    if (value === 'NUMBER') {
        return 'Number';
    }
    if (value === 'CURRENCY') {
        return 'Currency';
    }
    if (value === 'TIME') {
        return 'Time';
    }
    if (value === 'PROFILE_LINK') {
        return 'Profile Link';
    }
    return value;
};

export const getDataTypeOptions = (dataTypes: string[]) => {
    const types: DropdownOptionItem[] = [];
    dataTypes.forEach((item) => {
        if (item.toLowerCase() === 'enum') {
            types.push({
                value: 'SINGLE_SELECT',
                label: 'Single Select',
            });
            types.push({
                value: 'MULTI_SELECT',
                label: 'Multi Select',
            });
        } else {
            types.push({
                value: item,
                label: getLabel(item),
            });
        }
    });
    return types;
};

export const getUserId = (): string =>
    localStorage.getItem(localStorageKeys.USERID) || '';

export const getUserRole = () =>
    localStorage?.getItem(localStorageKeys.ROLE)
        ? localStorage?.getItem(localStorageKeys.ROLE)?.toLowerCase()
        : '';

export const getUserDetailsFromToken = (token = '') => {
    const accessToken =
        token || localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
    if (accessToken) {
        return JSON.parse(accessToken ? atob(accessToken?.split('.')[1]) : '');
    }
    return '';
};

export const getRoleBasedOnUserDetails = (token = '', defaultData = '') => {
    const accessToken =
        token || localStorage.getItem(localStorageKeys.ACCESS_TOKEN);
    let role = USER_ROLE.PARTICIPANT;
    if (accessToken || defaultData) {
        const {
            userRole,
            organizationUserRole,
            eventHubUserRole,
            eventUserRoles = [],
        } = defaultData ||
        JSON.parse(accessToken ? atob(accessToken?.split('.')[1]) : '');
        if (userRole?.toLowerCase() === USER_ROLE.SUPERADMIN) {
            role = USER_ROLE.SUPERADMIN;
        } else if (organizationUserRole?.toLowerCase() === USER_ROLE.ADMIN) {
            role = USER_ROLE.ORGANIZATION_ADMIN;
        } else if (eventHubUserRole?.toLowerCase() === USER_ROLE.ADMIN) {
            role = USER_ROLE.EVENT_HUB_ADMIN;
        } else if (
            eventUserRoles?.find(
                (item: any) =>
                    item?.role?.toLowerCase() === USER_ROLE.ADMIN ||
                    item?.eventRole?.toLowerCase() === USER_ROLE.ADMIN
            )
        ) {
            role = USER_ROLE.EVENT_ADMIN;
        }
        return role;
    }
    return '';
};

export const getUserData = (): string =>
    localStorage.getItem(localStorageKeys.USER_DATA)
        ? JSON.parse(localStorage.getItem(localStorageKeys.USER_DATA) || '')
        : '';

export const getColumnInRequiredFormatForCustomField = (
    item: ExistingColumn
) => {
    let enumItems: enumItemsType = [];
    // manage enumItems
    if (
        [
            constants.COMPONENTS.TABLE_DATA_TYPES.USER_DEFINED_ENUM,
            constants.COMPONENTS.TABLE_DATA_TYPES.ENUM,
        ].includes(item?.dataType?.toLowerCase())
    ) {
        if (item?.special && item?.special?.length > 0) {
            enumItems = item?.special.map((enumItem) => ({
                label: enumItem,
                value: enumItem,
            }));
        }

        if (
            item?.settings?.enumValues &&
            item?.settings?.enumValues.length > 0
        ) {
            enumItems = item?.settings?.enumValues.map((enumItem) => ({
                label: enumItem,
                value: enumItem,
            }));
        }
    }

    let isShowEditable = false;
    let dataType = item?.dataType;
    if (
        [
            DATE_AND_TIME.startDate,
            DATE_AND_TIME.endDate,
            DATE_AND_TIME.scheduleDate,
        ].includes(item.key)
    ) {
        dataType = constants.COMPONENTS.TABLE_DATA_TYPES.NORMAL_DATE;
        isShowEditable = true;
    }
    if (
        [
            DATE_AND_TIME.startTime,
            DATE_AND_TIME.endTime,
            DATE_AND_TIME.scheduleTime,
        ].includes(item.key)
    ) {
        dataType = constants.COMPONENTS.TABLE_DATA_TYPES.TIME;
        isShowEditable = true;
    }

    return {
        value: item.key,
        label: item.displayName,
        data: {
            // custom field settings
            isMandatory:
                item?.isRequired || item?.required || item?.settings?.required,
            isCustomField: item?.settings?.isCustomField || false,
            isMultiSelect: item?.settings?.isEnumMultiple || false,
            isShowEditable:
                (item?.settings?.isCustomField &&
                    [
                        constants.COMPONENTS.TABLE_DATA_TYPES.ENUM,
                        constants.COMPONENTS.TABLE_DATA_TYPES.DATE,
                    ].includes(item?.dataType?.toLowerCase())) ||
                isShowEditable ||
                false,
            dataType,
            enumItems,
        },
    };
};

export const getEventDateAndTime = (
    event: any,
    sFormat?: string,
    eFormat?: string
) =>
    event.startAt && event.endAt
        ? `${getDateForPreview(
              convertToTimeZone(event.startAt),
              sFormat || constants.GLOBAL.DATE_FORMATE_FOR_DISPLAY
          )} - ${getDateForPreview(
              convertToTimeZone(event.endAt),
              eFormat || constants.GLOBAL.DATE_FORMATE_FOR_DISPLAY
          )}`
        : '';

export const getFileNameForDisplay = (file: any) => {
    if (!file) return '';
    if (typeof file === 'object' && file.name) {
        return file.name;
    }
    return file?.split('/')?.reverse()[0] || '';
};

export const getFileUrl = (file: any) => {
    if (typeof file === 'object' && file.name) {
        return URL.createObjectURL(file);
    }
    return file;
};

export const imageDimensions = (file: any) =>
    new Promise((resolve) => {
        const img = new Image();

        // the following handler will fire after the successful loading of the image
        img.onload = () => {
            const { naturalWidth: width, naturalHeight: height } = img;
            resolve({ width, height });
        };

        // and this handler will fire if there was an error with the image (like if it's not really an image or a corrupted one)
        img.onerror = () => {
            resolve({ width: 0, height: 0 });
        };

        img.src = URL.createObjectURL(file);
    });

export const handleFileDownload = (file: any, fileName: any) => {
    if (file) {
        if (typeof file === 'object' && file.name) {
            downloadFileFromUrl(URL.createObjectURL(file), fileName);
            return;
        }
        downloadFileFromUrl(file, fileName);
    }
};

export const openLinkInNewTab = (url: string, isOpenUrlInSameTab = false) => {
    const win = window.open(url, isOpenUrlInSameTab ? '_self' : '_blank');
    if (win != null) {
        win.focus();
    }
};

export const calculatePercentage = (value: number, total: number) => {
    if (total === 0) return '0.00';
    return ((value / total) * 100).toFixed(2);
};

export const handleFontFamilyChange = (fontStylesWithFontFamily: any) => {
    for (let i = 1; i < 7; i += 1) {
        const elements: any = document.getElementsByTagName(`h${i}`);
        for (let j = 0; j < elements.length; j += 1) {
            if (
                !elements[j].style.fontFamily ||
                elements[j].style.fontFamily !==
                    fontStylesWithFontFamily[`h${i}`]
            )
                elements[j].style.fontFamily =
                    fontStylesWithFontFamily[`h${i}`];
        }
    }
};

export const validateConditionsData = (
    rules: any,
    setRules: any,
    isPrimaryButtonDisabled: boolean
) => {
    if (isPrimaryButtonDisabled) return;
    // save detail and show success message
    let isError = false;
    _.map(rules, (ruleItem) => {
        const fieldType = ruleItem.field[0]?.settings?.type;
        const ruleItemData = ruleItem;
        if (
            (ruleItem.isValueRequired && isEmpty(ruleItem.value)) ||
            (ruleItem.isValueRequired &&
                fieldType === constants.GLOBAL.ENUM &&
                ruleItem?.value?.length === 0)
        ) {
            ruleItemData.valueErrorMessage =
                fieldType === constants.GLOBAL.ENUM
                    ? 'Please select value'
                    : `Please enter value`;
            isError = true;
        }
    });
    if (isError) {
        setRules(_.cloneDeep(rules));
        return true;
    }

    return false;
};

export const reorderArrayForDragAndDrop = (
    newArray: any[],
    oldArray: any[],
    dropOnId: string | number,
    draggedId: string | number
) => {
    const dropOnItemIndex = newArray.findIndex(
        (item) => `${item.id}` === `${dropOnId}`
    );
    const draggedItemIndex = newArray.findIndex(
        (item) => `${item.id}` === `${draggedId}`
    );
    if (draggedItemIndex === dropOnItemIndex) return oldArray;
    const draggingItemContent = newArray[draggedItemIndex];
    newArray.splice(draggedItemIndex, 1);
    newArray.splice(dropOnItemIndex, 0, draggingItemContent);
    const arrayOfChangeableOrderItemsOrder = oldArray.map((item) => item.order);
    arrayOfChangeableOrderItemsOrder.forEach((item, itemIndex) => {
        newArray[itemIndex].order = item;
    });
    return orderBy([...newArray], 'order');
};

export const columnCentered = {
    headerClass: 'text-center',
    cellStyle: {
        textAlign: 'center',
        justifyContent: 'center',
    },
};

function replaceAll(haystack: string, needle: string, replacement: string) {
    return haystack.split(needle).join(replacement);
}

const linkifyEmails = (text: string) => {
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href=["']([^"']*)/gi;

    // First, find all existing links
    const links: string[] = [];
    const matches = Array.from(text.matchAll(linkRegex));
    matches.forEach((match) => {
        links.push(match[1]);
    });

    // Then, replace emails that are not already in links
    return text.replace(emailRegex, (email) => {
        // Check if this email is already part of a link
        if (links.some((link) => link.includes(email))) {
            return email; // Return the email as-is if it's already in a link
        }
        return `<a href="mailto:${email}">${email}</a>`;
    });
};

export const getTextToShow = (
    text: string,
    dataTagsWithValues: { id: string; value: string }[]
) => {
    let textData = linkifyEmails(text);
    dataTagsWithValues.forEach((tagItem: { id: string; value: string }) => {
        if (text?.includes(tagItem.id)) {
            let value = tagItem?.value || '';
            // if value is valid link will append anchor tag
            if (isValidLink(tagItem?.value)) {
                value = `<a href="${tagItem?.value}" alt='link' >${tagItem?.value}</a>`;
            }
            textData = replaceAll(textData, tagItem.id, value);
        }
    });

    return textData;
};

export const getTextFromHtmlToShow = (html = '') => {
    try {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const text = doc.body.textContent || '';
        return text;
    } catch (error) {
        return html;
    }
};

export const getUniqueId = () => `id-${getDateMiliseconds(`${new Date()}`)}`;

export const isValidLink = (url: string) =>
    validateLink(url) || /(http(s?)):\/\//i.test(url);

export const isValidPadding = (padding: string) => {
    // Define a regular expression to match valid padding values
    const validPaddingPattern = /^(\d+px\s*){1,4}$/;

    // Check if the padding matches the valid pattern
    if (validPaddingPattern.test(padding)) {
        return true; // Valid padding
    }
    return false; // Invalid padding
};

/**
 * Sort array of objects based on another array
 */

export const mapOrder = (array: any[], order: string[], key: string) => {
    array.sort((a, b) => {
        const A = a[key];
        const B = b[key];
        if (order.indexOf(A) > order.indexOf(B)) {
            return 1;
        }
        return -1;
    });

    return array;
};

const getLocationLatLng = async (address: string) => {
    try {
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
        const response = await fetch(url);
        const data = await response?.json();
        const locationData = data?.results[0]?.geometry?.location;
        return {
            lat: locationData?.lat || null,
            lng: locationData?.lng || null,
        };
    } catch (error) {
        return { lat: null, lng: null };
    }
};

const getTimeZone = async (lat: number | null, lng: number | null) => {
    if (lat && lng) {
        const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${
            Date.now() / 1000
        }&key=${apiKey}`;
        const response = await fetch(url);
        const data = await response?.json();
        return data?.timeZoneId || null;
    }
    return null;
};

export const getAddressTimeZoneId = async (address: string) => {
    const { lat, lng } = await getLocationLatLng(address);
    const timeZoneId = await getTimeZone(lat, lng);
    // google api return deprecated timezone id so we used countries-and-timezones library to get valid timezone
    return (timeZoneId && ct?.getTimezone(timeZoneId)?.aliasOf) || timeZoneId;
};

export const getSessionQRCodeValue = ({
    eventId,
    sessionId,
}: {
    eventId: string;
    sessionId: string;
}) =>
    JSON.stringify({
        type: 'SESSION_QR_CODE',
        eventId,
        sessionId,
    });

export const getCustomQRCodeValue = ({
    eventId,
    customQRCodeId,
}: {
    eventId: string;
    customQRCodeId: string;
}) =>
    JSON.stringify({
        type: 'CUSTOM_QR_CODE',
        eventId,
        customQRCodeId,
    });

export const getParticipantQRCodeValue = ({
    eventId,
    participantId,
}: {
    eventId: string;
    participantId: string;
}) =>
    JSON.stringify({
        type: 'PARTICIPANT_QR_CODE',
        eventId,
        participantId,
    });

export const updateLoginType = (type: string) => {
    const isEventLogin = window.location.pathname.includes(eventUrl);
    let existingType = '';
    if (isAdminUser) {
        localStorage.setItem(localStorageKeys.LOGIN_TYPE, admin);
        existingType = admin;
    } else if (isEventLogin) {
        localStorage.setItem(localStorageKeys.LOGIN_TYPE, eventUrl);
        existingType = eventUrl;
    } else {
        localStorage.setItem(localStorageKeys.LOGIN_TYPE, '');
    }
    return !!(existingType === type);
};

export const validateLoginType = (type: string) => {
    const loginType = localStorage.getItem(localStorageKeys.LOGIN_TYPE);
    return !!(loginType === type);
};

export const defaultParamsForTextField = () => ({
    field: [
        {
            settings: {
                type: constants.GLOBAL.TEXT_FIELD,
            },
        },
    ],
    label: 'Text Field',
    value: '',
    isValueRequired: false,
    isSystemMandatory: false,
    isConditionalField: false,
    conditionData: '',
    data: {
        id: constants.GLOBAL.TEXT_FIELD,
    },
});

export const checkIsTokenExpired = (accessToken: string | undefined) => {
    if (!accessToken) return true;
    const { exp } = JSON.parse(atob(accessToken.split('.')[1])) || {};
    const expired =
        accessToken && (!exp || exp < Math.floor(new Date().getTime() / 1000));
    return expired;
};

export const isArrayEqual = (arr1: any[], arr2: any[], keys: string[]) => {
    // Sort the arrays to ensure consistent order
    const oldData = _.map(arr1, _.partialRight(_.pick, [...keys]));
    const newData = _.map(arr2, _.partialRight(_.pick, [...keys]));
    return _.isEqual(newData, oldData);
};

// uncomment if need to prevent api call on focus or reconnect
export const swrCallSettings = {
    // revalidateOnFocus: false,
    // revalidateOnReconnect: false,
    // revalidateIfStale: false,
};

export const checkIsDateWithinTwoWeeksRange = (
    selectedDateString: string,
    currentDate: Date = new Date()
): boolean => {
    const selectedDateParts = selectedDateString.split('-');
    const selectedYear = parseInt(selectedDateParts[2], 10);
    const selectedMonth = parseInt(selectedDateParts[0], 10) - 1; // Month (0-based, subtract 1)
    const selectedDay = parseInt(selectedDateParts[1], 10);

    const currentDateYear = currentDate.getFullYear();
    const currentDateMonth = currentDate.getMonth();
    const currentDateDay = currentDate.getDate();

    // Create Date objects without the time component
    const selectedDateWithoutTime = new Date(
        selectedYear,
        selectedMonth,
        selectedDay
    );
    const currentDateWithoutTime = new Date(
        currentDateYear,
        currentDateMonth,
        currentDateDay
    );

    // Calculate the two-week range
    const twoWeeksFromNowEnd = new Date(currentDateWithoutTime);
    twoWeeksFromNowEnd.setDate(currentDateWithoutTime.getDate() + 13); // Add 14 days

    return (
        selectedDateWithoutTime >= currentDateWithoutTime &&
        selectedDateWithoutTime <= twoWeeksFromNowEnd
    );
};

let idCounter = 0;
export const getUniqueIdBasedOnKey = (key: string) => {
    // Generate a unique ID based on the current timestamp and the counter
    const uniqueID = `${getDateMiliseconds()}-${key}-${idCounter}`;
    // Increment the counter to ensure the next ID will be different
    idCounter += 1;
    return uniqueID;
};

export const getEventWebsiteRoute = (eventId: string, pageId: string) =>
    routesPaths.EVENT_WEBSITE.replace(':eventId', eventId)
        .replace(':pageId', pageId)
        .replace(':type?', '');

export const checkMobileApp = () => {
    const tokenFromMobileApp = getMobileAppToken();
    return !!tokenFromMobileApp;
};

export const getMobileAppToken = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const mobileToken = urlParams.get(mToken);
    return mobileToken;
};

export const getToken = (key: string) => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const token = urlParams.get(key);
    return token;
};

export const getMobileAppType = () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    return urlParams.get(mobileType);
};

export const getPlayStoreOrAppStoreUrl = () => {
    // const host = 'trhq.events'; // uncomment to test locally
    const hub = hostname.replace('cms.', '').replace('.covision.com', '');

    const details = {
        playStoreOrAppStoreUrl: '',
        appId: '',
        title: '',
        author: 'covision',
        icon: '',
    };

    details.playStoreOrAppStoreUrl =
        'https://play.google.com/store/apps/details?id=';
    switch (hub) {
        case 'trhq.events':
            details.appId = 'com.trailrunnerhq.app';
            details.title = 'Trailrunner HQ';
            details.icon = 'trhq-events.png';
            break;
        case 'events':
            details.appId = 'com.covision';
            details.title = 'Covision Events';
            details.icon = 'events-android.webp';
            break;
        case 'mckinsey':
            details.appId = 'com.mckinsey.onsite';
            details.title = 'McKinsey Onsite';
            details.icon = 'mckinsey-onsite.webp';
            break;
        case 'staging':
            details.appId = 'com.covision';
            details.title = 'Covision';
            details.icon = 'events-android.webp';
            break;
        default:
            details.appId = 'com.covision';
            details.title = 'Covision';
            details.icon = 'events-android.webp';
            break;
    }

    if (!isAndroid) {
        details.playStoreOrAppStoreUrl = 'https://apps.apple.com/app/';
        switch (hub) {
            case 'trhq.events':
                details.playStoreOrAppStoreUrl += 'trailrunner-hq/id';
                details.appId = '6698850380';
                details.title = 'Trailrunner HQ';
                details.icon = 'trhq-events.png';
                break;
            case 'events':
                details.playStoreOrAppStoreUrl += 'covision-events/id';
                details.appId = '1668850124';
                details.title = 'Covision Events';
                details.icon = 'events-ios.webp';
                break;
            case 'mckinsey':
                details.playStoreOrAppStoreUrl += 'mckinsey-onsite/id';
                details.appId = '1481327980';
                details.title = 'McKinsey Onsite';
                details.icon = 'mckinsey-onsite.webp';
                break;
            case 'staging':
                details.playStoreOrAppStoreUrl += 'covision-events/id';
                details.appId = '1668850124';
                details.title = 'Covision';
                details.icon = 'events-ios.webp';
                break;
            default:
                details.playStoreOrAppStoreUrl += 'covision-events/id';
                details.appId = '1668850124';
                details.title = 'Covision';
                details.icon = 'events-ios.webp';
                break;
        }
    }

    details.playStoreOrAppStoreUrl += details.appId;
    return details;
};

export const getPinIconBasedOnType = (type: string) => {
    switch (type) {
        case PIN_TYPES.SESSION:
            return SessionIcon;
        case PIN_TYPES.EXHIBITOR:
            return PersonIcon;
        case PIN_TYPES.OTHER:
            return LocationIcon;
        default:
            return LocationIcon;
    }
};
