import axios from 'axios';
import localStorageKeys from 'constants/localStorageKeys';
import storeAndPersistor from 'redux/configureStore';
import fetchClient from 'utils/axiosConfig';
import { getUserId } from 'utils/common';
import constants from 'utils/constants';
import * as types from './commonTypes';

export const exportData = (apiUrl, ids = '', method = 'get', requestData) => {
    const url = ids ? `${apiUrl}?ids=${JSON.stringify(ids)}` : apiUrl;
    if (method === 'get') {
        return fetchClient.get(url);
    }
    return fetchClient.post(url, requestData);
};

export const getCurrentSelectedEventId = () => {
    const { store } = storeAndPersistor;
    const { home } = store.getState();
    return home?.selectedEvent?.eventId || '';
};

export const getCurrentSelectedEvent = () => {
    const { store } = storeAndPersistor;
    const { home } = store.getState();
    return home?.selectedEvent || '';
};

export const getViewSettings = (tableKey) => {
    try {
        const userId = getUserId();
        const updateTableKey = `${userId}-${tableKey}`;
        const url = constants.API.COMMON.VIEW_SETTINGS;
        return fetchClient
            .get(`${url}/${updateTableKey}`)
            .then(({ data }) => ({
                success: true,
                settings: data?.settings,
            }))
            .catch(({ message }) => ({ success: false, message }));
    } catch {
        return { success: false };
    }
};

export const addEditViewSettings = (
    tableKey,
    isAddRequest = true,
    requestData
) => {
    try {
        const userId = getUserId();
        const updateTableKey = `${userId}-${tableKey}`;
        requestData.tableKey = updateTableKey;
        const url = constants.API.COMMON.VIEW_SETTINGS;
        const query = isAddRequest
            ? fetchClient.post(url, requestData)
            : fetchClient.patch(`${url}/${updateTableKey}`, requestData);
        return query
            .then(({ data }) => ({
                success: true,
                settings: data?.settings,
            }))
            .catch(({ message }) => ({ success: false, message }));
    } catch {
        return { success: false };
    }
};

export const getUserDetailsById = (userId) => {
    try {
        const url = constants.API.COMMON.USER_DETAILS.replace(':id', userId);
        return fetchClient
            .get(url)
            .then(({ data }) => ({ success: true, userDetails: data }))
            .catch(({ message }) => ({ success: false, message }));
    } catch {
        return { success: false };
    }
};

// cancel previuse request so data not get overlap
let cancelToken;
export const getUserProfileById = (eventId, profileDataForPreview) => {
    try {
        // Check if there are any previous pending requests
        if (typeof cancelToken !== typeof undefined) {
            cancelToken.cancel('Operation canceled due to new request.');
        }
        // Save the cancel token for the current request
        cancelToken = axios.CancelToken.source();

        const url = constants.API.COMMON.USER_PROFILE.replace(
            ':eventId',
            eventId
        );
        let request;
        if (profileDataForPreview) {
            request = fetchClient.post(
                url,
                {
                    ...profileDataForPreview,
                    ...profileDataForPreview.settings,
                },
                { cancelToken: cancelToken.token }
            );
        } else {
            request = fetchClient.get(url, { cancelToken: cancelToken.token });
        }

        return request
            .then(({ data }) => ({
                success: true,
                data,
            }))
            .catch(({ message, response }) => ({
                success: false,
                message: response?.data?.message || message,
            }));
    } catch {
        return { success: false };
    }
};

export const handleParticipantViewToggle = (
    dispatch,
    isParticipantView,
    push
) => {
    localStorage.setItem(
        localStorageKeys.PARTICIPANT_VIEW,
        `${isParticipantView}`
    );
    dispatch(toggleParticipantView(isParticipantView));
    if (push) push('/');
};

export const toggleParticipantView = (isParticipantView) => ({
    type: types.IS_PARTICIPANT_VIEW,
    isParticipantView,
});

export const updateDataTagsValues = (dataTagsWithValues) => ({
    type: types.DATA_TAGS_VALUES,
    dataTagsWithValues,
});

export const updateDataTagsOptions = (dataTagsOptions) => ({
    type: types.DATA_TAGS_OPTIONS,
    dataTagsOptions,
});

export const updateMainDataTagsOptions = (mainDataTags) => ({
    type: types.MAIN_DATA_TAGS_OPTIONS,
    mainDataTags,
});

export const getDataTagsValues = (eventId) => async (dispatch) => {
    try {
        const url = constants.API.EVENTS.DATA_TAGS_VALUES.replace(
            ':eventId',
            eventId
        );
        return fetchClient
            .get(url)
            .then(({ data: { data } }) => {
                dispatch(updateDataTagsValues(data));
                return { success: true };
            })
            .catch(({ message }) => ({ success: false, message }));
    } catch ({ message }) {
        return { success: false, message };
    }
};

export const getDataTags = (eventId) => async (dispatch) => {
    try {
        const url = constants.API.EVENTS.DATA_TAGS.replace(':eventId', eventId);
        return fetchClient
            .get(url)
            .then(({ data }) => {
                dispatch(updateDataTagsOptions(data?.tags || []));
                dispatch(
                    updateMainDataTagsOptions(
                        data?.mainTags
                            ? [
                                  {
                                      value: 'ALL_TAGS',
                                      label: 'All Tags',
                                  },
                                  ...data?.mainTags,
                              ]
                            : []
                    )
                );
                return { success: true };
            })
            .catch(({ message }) => ({ success: false, message }));
    } catch ({ message }) {
        return { success: false, message };
    }
};

export const toggleAttendeeFeedbackModal = (isOpen) => ({
    type: types.ATTENDEE_FEEDBACK_MODAL,
    attendeeFeedbackModal: isOpen,
});

export const saveAttendeeFeedbackAction = (requestData, eventId) => {
    const url = constants.API.COMMON.ATTENDEE_FEEDBACK.replace(
        ':eventId',
        eventId
    );
    return fetchClient.post(url, requestData);
};
