import { produce } from 'immer';
import * as types from './labelTypes';
import createReducer from '../utils/createReducer';

const initialState = {
    records: [],
    totalRecords: 0,
    loading: true,
};

const actionHandlers = {
    [types.GET_LABELS.REQUEST]: produce((draft) => {
        draft.loading = true;
    }),
    [types.GET_LABELS.SUCCESS]: produce((draft, { total, labels }) => {
        const records = labels.map((item) => ({
            ...item,
            createdBy: item?.createdBy?.firstName
                ? `${item?.createdBy?.firstName} ${item?.createdBy?.lastName}`
                : '',
        }));
        draft.records = records;
        draft.totalRecords = total;
        draft.loading = false;
    }),
    [types.GET_LABELS.FAILURE]: produce((draft) => {
        draft.loading = false;
    }),
    [types.RESET_LABELS]: produce((draft) => {
        draft.records = [];
        draft.totalRecords = 0;
        draft.loading = true;
    }),
};

export default createReducer(initialState, actionHandlers);
